// Popup Add
function getCookie(name, val) {
    var cookie = document.cookie.split(';')
        .map(function (x) {
            return x.trim().split('=');
        })
        .filter(function (x) {
            return x[0] === name;
        })
        .pop();

    if (val) {
        return cookie && cookie[1] === val;
    } else {
        return cookie;
    }
}

// Set Cookie

function showPopupAd() {
  
        var cookieName = 'popupAd';
        var cooKey = 'mtpPopupAd';
        var applyClosed = document.cookie.split(';')
            .map(function (x) {
                return x.trim().split('=');
            })
            .filter(function (x) {
                return x[0] === cookieName;
            })
            .pop();

        if (applyClosed) {
            $('#my_popup').remove();
            return;
        }

        function setCookie() {
            var days = 7;
            var myDate = new Date();
            myDate.setTime(myDate.getTime() + (days * 24 * 60 * 60 * 1000));
            document.cookie = cookieName + '=' + cooKey + '; expires=' + myDate.toGMTString();
        }
  
}

$(document).ready(function () {
    if (getCookie('LocationSelected', 'Success')) {
    if (!$('#my_popup').length) return;

    var cookieName = 'popupAd';
    var cooKey = 'mtpPopupAd';
    var applyClosed = document.cookie.split(';')
        .map(function (x) {
            return x.trim().split('=');
        })
        .filter(function (x) {
            return x[0] === cookieName;
        })
        .pop();

    function setCookie() {
        var days = 7;
        var myDate = new Date();
        myDate.setTime(myDate.getTime() + (days * 24 * 60 * 60 * 1000));
        document.cookie = cookieName + '=' + cooKey + '; expires=' + myDate.toGMTString();
    }

    setCookie();

    // Initialize the plugin
    $('#my_popup').popup({
        autoopen: !applyClosed,
        onopen: function () {
            $('.popup-container').hide();

        },
        onclose: function () {
            $('.popup-container').show();
        }
    });

    $(".close-icon").click(function () {
        $('.popup-container').hide();
    });
    }
});