import { scrollToElement } from './helpers';

$(function () {
	$('.menus-nav').each(function () {
		const $menusNav = $(this);
		if ($(window).width() <= 768) {
			const $form = $menusNav.find('.anchors-mobile');

			const stickyPoint = $form.offset().top - $('.header').height(); // scroll location at which dropdown becomes sticky
			const threshold = $('.header').height(); // offset from dropdown to top of screen
			const fullObstructionHeight = Math.floor($('.header').height() + $('.menus-nav').height() + 125); // scrollToElement adds a static 100px offset for the height of the nav + 20 for the dropdown margin + 5px offset to catch outliers

			$menusNav.closest('.menus-wrap').attr('data-scroll-offset', $form.height() + 20);

			$menusNav.css('top', threshold);


			$(document).on('scroll', function (e) {
				const st = $(window).scrollTop();
				$menusNav.toggleClass('sticky', st >= stickyPoint);

				$('.menu-section').each(function () {
					let scrollTrigger = Math.floor($(this).offset().top - fullObstructionHeight);
					if (st >= scrollTrigger) {
						const id = $(this).attr('id');
						$form.find('.anchors-select').val(`#${id}`);
					}
				});
			});

			$menusNav.on('change', '.anchors-select', function () {
				const val = $(this).val();
				scrollToElement(val);
			});
		}
	});
});