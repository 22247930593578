// region Form Field State Classes
// Handles input states as class names on the parent to assist with styling when needed
const formFieldSelector = '.form-field input, .form-field textarea, .form-field select';
const prefilledTypes = ['date', 'month']; // These always have text in the text box, so the label should always be in the active state
$(formFieldSelector).each(function () {
    $(this).closest('.form-field').toggleClass('not-empty', $(this).val() !== '');

    if ($(this).attr('required') && $(this).attr('required') !== '') {
        $(this).closest('.form-field').addClass('field-required');
        $(this).closest('.form-group').addClass('group-has-required');
    }

    if (!!prefilledTypes.filter(e => e === this.type)[0]) {
        $(this).closest('.form-field').addClass('prefilled');
    }
});

$(document).on('focus', formFieldSelector, function () {
    $(this).closest('.form-field').addClass('focused');
});

$(document).on('blur', formFieldSelector, function () {
    $(this).closest('.form-field').removeClass('focused');
});

$(document).on('keydown keyup change load input', formFieldSelector, function () {
    $(this).closest('.form-field').toggleClass('not-empty', $(this).val() !== '');
});

var dateFirst = $('#PreferredDate');
var dateLast = $('#SecondaryDate');



$('.form-ajax').on('submit', function (e) {
    e.preventDefault();

    const $form = $(this);
    const $wrap = $form.parent('.form-wrapper');
    const $message = $wrap.find('.form-success');
    const $btn = $form.find('.button--formsubmit');

    const action = $form.attr('action');
    const data = new FormData($form[0]);

    let parsleyInstance = $form.parsley();

    parsleyInstance.on('field:error', (fieldInstance) => {
        console.log(fieldInstance.validationResult);
        if (!$(fieldInstance.$element).is(':visible')) {
            fieldInstance.validationResult = true;
        }
    });

    if (parsleyInstance.isValid()) {
        $btn.prop('disabled', true);
        //Begin ajax method
        $.ajax({
            url: action,
            method: 'POST',
            data: data,
            processData: false,
            contentType: false,
            encType: 'multipart/form-data',
            success: function (response) {
                $message.show('slow');
                $message.focus();
                $form[0].reset();
                $form.hide('slow');
            }, error: function (err) {
                //alert(err.responseText);
                $(".g-recaptcha").addClass("recaptcha-border");
                $('#error-msg').show("slow");
                $('#error-msg').focus();

            },
            complete: function () {
                $btn.prop('disabled', false);
                if (grecaptcha) grecaptcha.reset();
            }
        });
    }
});