$(function () {
    document.getElementsByClassName('email-signup-top')[0].style.visibility = "hidden";
    $(".splash-button").on("click", function (e) {
        //alert(0);
        e.currentTarget.href = e.currentTarget.href + '?LocationSelected=Success'
        $('body').css('overflow-y', 'auto');
        $('.splash-page').hide();
        $('body').css('overflow-y', 'auto');
        document.getElementsByClassName('email-signup-top')[0].style.visibility = "unset";
        setCookie("LocationSelected", "Success");
    })
    function setCookie(name, val, duration) {
        var days = duration;
        var now = new Date();
        now.setTime(now.getTime() + (days * 24 * 60 * 60 * 1000));
        document.cookie = name + '=' + val + '; expires=' + now.toGMTString();
    }


    function getCookie(name, val) {
        var subdomain = "";
        var host = window.location.host
        subdomain = host.split('.')[0];

        if (getParameterByName('LocationSelected')) {
            setCookie("LocationSelected", "Success");
            window.history.pushState({}, document.title, window.location.pathname);
        }
        else if (subdomain === "milwaukee" || subdomain === "lincoln" || subdomain === "mtpm" || subdomain === "mtpl")
        {
            setCookie("LocationSelected", "Success");
        }
        var cookie = document.cookie.split(';')
            .map(function (x) {
                return x.trim().split('=');
            })
            .filter(function (x) {
                return x[0] === name;
            })
            .pop();

        if (val) {
            return cookie && cookie[1] === val;
        } else {
            return cookie;
        }
    }
    function getParameterByName(name) {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }
    if (!getCookie('LocationSelected', 'Success')) {
        //alert();
        $('body').css('overflow-y', 'hidden');
        $('.splash-page').show();
    }
});