// region jQuery Plugins
import 'slick-carousel';
// endregion

import AOS from 'aos';

// region Other Vendors
import '@fortawesome/fontawesome-pro/js/all.min';
// endregion
import './vendor/jquery-popup-overlay';


import './components/splash';
import './components/helpers';
import './components/form';
import './components/email';
import './components/instagram-feeds';
import './components/menu';
import "./components/navigation";
import './components/popup';


import { isEditMode, scrollToElement } from './components/helpers';
import * as ADMIN_CONSTANTS from './admin/admin-constants';

window.ADMIN_CONSTANTS = ADMIN_CONSTANTS;

$(function(){
	$(document).on('click', 'a[href^="#"]', function (e) {
		const targetSelector = $(this).attr('href');

		if (targetSelector === '#' && $(this).hasClass('back-to-top')) {
			$('html, body').animate({
				scrollTop: 0
			}, 1000);
			return false;
		}

		if (scrollToElement(targetSelector)) e.preventDefault();
	});
})

if (!isEditMode) {
	$('[data-aos]').each(function () {
		// AOS doesn't let you set a default for this...
		$(this).attr('data-aos-anchor-placement', 'center-bottom');
	});

	AOS.init({
		once: true,
		offset: 0
	});
}

setInterval(function () {
	AOS.refresh();
	AOS.refreshHard();
}, 1000 / 60);