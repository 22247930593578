
$.fn.serializeFormJSON = function () {

	var o = {};
	var a = this.serializeArray();
	$.each(a, function () {
		if (o[this.name]) {
			if (!o[this.name].push) {
				o[this.name] = [o[this.name]];
			}
			o[this.name].push(this.value || '');
		} else {
			o[this.name] = this.value || '';
		}
	});
	return o;
};


$('#EmailSignUpForm').on('submit', function (e) {
	e.preventDefault();

	var action = $(this).attr('action');
	var data = $(this).serializeFormJSON();


	if ($(this).parsley().isValid()) {
		$(".footer-email-form-submit").prop("disabled", true);
		//Begin ajax method
		$.ajax({
			url: action,
			method: 'POST',
			data: data,
			success: function (msg) {				
				
				$('form').hide("slow");
				
					$('#msg').show("slow");
					$('#msg').focus();
				
				$('#EmailSignUpForm')[0].reset();
				window.location = '/subscribe/success';

			}, error: function (e) {
                //alert(e.responseText);
                

			},
			complete: function () {
				$(".footer-email-form-submit").prop("disabled", false);
			}
		});
	};

});