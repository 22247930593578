$(function(){
    $(".footer-location-instagram-one").slick({
        slidesToShow: 1,
        slidesToScroll: 2,    
        initialSlide: 0,
        infinite: true,
        speed: 500,
        useCSS: false,
        arrows: false,
        dots: false,
        fade:true,
        cssEase: "linear",
        autoplay: true
    })
    $(".footer-location-instagram-two").slick({
        slidesToShow: 1,
        slidesToScroll: 2,    
        initialSlide: 0,
        infinite: true,
        speed: 500,
        useCSS: false,
        arrows: false,
        dots: false,
        fade:true,
        cssEase: "linear",
        autoplay: true
    })
});
