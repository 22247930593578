export const EDITOR_FORMATTING_OPTIONS = [
	{ text: 'Outline Button', value: 'a.button button-2' }
];

export const EDITOR_TOOLS = [
	"bold",
	"italic",
	"underline",
	"justifyLeft",
	"justifyRight",
	"justifyCenter",
	"insertUnorderedList",
	"insertOrderedList",
	"createLink",
	"viewHtml"
];